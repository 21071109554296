





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import config from '../config';
import VirtualList from 'vue-virtual-scroll-list';
import Member from '@/components/Member.vue';
import MemberAddFindQuestion from '@/components/MemberAddFindQuestion.vue';
import axios from 'axios';
import eventBus from '../event-bus';

@Component({
  components: {
    'virtual-list': VirtualList,
    'member-add-find-question': MemberAddFindQuestion,
  },
})
export default class ManageBuildingUsers extends Vue {
  @Prop() public building!: any;
  public $store: any;
  public $q: any;
  public $apiget: any;
  public members: any = [];
  public itemComponent: any = Member;
  public memberFilter: any = '';
  public showAddUser: boolean = false;

  public created() {
    this.refresh();
    eventBus.$on('REMOVE_BUILDING_ACCESS', (user: any) => {
      axios.patch(
      `/organisation/member/${user.id}/detach-buildings`,
      {
        building_ids: [this.building.id],
      }).then((response: any) => {
        this.refresh();
      }).catch((error: any) => {
        this.globalError(error);
      });
    });

    eventBus.$on('ADD_BUILDING_ACCESS', (user: any) => {
      axios.patch(
      `/organisation/member/${user.id}/attach-buildings`,
      {
        building_ids: [this.building.id],
      }).then((response: any) => {
        this.refresh();
      }).catch((error: any) => {
        this.globalError(error);
      });
    });

    eventBus.$on('NEW_BUILDING_USER', (user: any) => {
      this.showAddUser = false;
      eventBus.$emit('ADD_BUILDING_ACCESS', user);
    });
  }

  @Watch('building')
  private refresh() {
    if (this.building === undefined || this.building.id === undefined) {
      return;
    }
    this.$apiget(`/organisation/building/${this.building.id}/members`)
      .then((response: any) => {
        this.members = Object.freeze(response.members);
      });
  }

  get filteredMembers() {
    if (this.memberFilter !== '') {
      const text = this.memberFilter.toLowerCase();
      return this.members.filter((member: any) => {
        return (
          member.lastname.toLowerCase().indexOf(text) >= 0
          || member.firstname.toLowerCase().indexOf(text) >= 0
        );
      });
    }
    return this.members;
  }

}
