



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import eventBus from '../event-bus';

@Component({})
export default class Member extends Vue {
  @Prop() public source!: any;
  @Prop() public index!: any;
  @Prop() public mode!: string;

  get modeCss() {
    return this.mode || 'view';
  }

  private addBuildingAccess() {
    eventBus.$emit('ADD_BUILDING_ACCESS', this.source);
  }

  private removeBuildingAccessConfirm() {
    this.$q.dialog({
      title: this.$tc('remove_building_access'),
      message: (this.$t('remove_building_access_confirm', {
        user: `${this.source.firstname} ${this.source.lastname}`,
      }) as string),
      ok: {
        label: this.$t('confirm'),
      },
      cancel: {
        label: this.$t('cancel'),
        flat: true,
      },
    }).onOk(() => {
      eventBus.$emit('REMOVE_BUILDING_ACCESS', this.source);
    });
  }
}
