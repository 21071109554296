










































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VirtualList from 'vue-virtual-scroll-list';
import Member from '@/components/Member.vue';
import axios from 'axios';
import eventBus from '../event-bus';

@Component({
  components: {
  'virtual-list': VirtualList,
  },
})
export default class MemberAddFindQuestion extends Vue {
  @Prop() public building!: any;
  @Prop() public buildingMembers!: any;
  public $apiget: any;
  public mode: string = 'create';
  public itemComponent: any = Member;
  public memberFilter: string = '';
  public members: any = [];
  public error: string|boolean = false;
  public importLoading: boolean = false;
  public member: any = {
    lastname: '',
    firstname: '',
    mobile: '',
    email: '',
  };
  public formSubmitting: boolean = false;

  public mounted() {
    this.mode = 'choice';
  }

  public createUserAction() {
    this.mode = 'create';
  }

  public importUserAction() {
    this.importLoading = true;
    this.mode = 'import';
    this.$apiget('/organisation/members')
    .then((response: any) => {
      this.members = response.members.filter((member: any) => {
        return !member.building_ids.includes(this.building.id);
      });
      this.importLoading = false;
    });
  }

  public onCreateUser() {
    this.formSubmitting = true;
    axios.post('/organisation/members', {
      email: this.member.email,
      firstname: this.member.firstname,
      lastname: this.member.lastname,
      mobile: this.member.mobile,
    })
    .then((response: any) => {
      this.$emit('selectedUser', response.data);
      eventBus.$emit('NEW_BUILDING_USER', response.data);
      this.formSubmitting = false;
    })
    .catch((error: any) => {
      if (error.status === 500) {
        this.error = error.message;
      }
      this.formSubmitting = false;
    });
  }

  get filteredMembers() {
    if (this.building === undefined) {
      return [];
    }
    const uids = this.buildingMembers.map(
      (member: any) => member.id);
    const members = this.members.filter((member: any) => {
      return !uids.includes(member.id);
    });
    if (this.memberFilter !== '') {
      const text = this.memberFilter.toLowerCase();
      return members.filter((member: any) => {
        return (
          member.lastname.toLowerCase().indexOf(text) >= 0
          || member.firstname.toLowerCase().indexOf(text) >= 0
        );
      });
    }
    return members;
  }

}
